$(document).ready(function() {

	// Initialize the map on the "map" div with a given center and zoom (fallback for location error)
	var map = L.map( 'map', {
		center: [53.5686, 9.9841],
		zoom: 16,
		detectRetina: true,
		scrollWheelZoom: false,
		// Leaflet.fullscreen - A HTML5 fullscreen plugin for Leaflet.
		 fullscreenControl: true
	});

	// Layer CartoDB
	var layer = L.tileLayer( 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
		minZoom: 7,
		opacity: 0.8,
		attribution: 'Engineered by <a href="//metamaps.de" title="MetaMaps — Maps build with passion.">MetaMaps</a> | ' + 'Data © <a href="//openstreetmap.org" title="OpenStreetMap">OpenStreetMap</a> contributors, ' + ' <a href="//opendatacommons.org/licenses/odbl/" title="Open Data Commons Open Database License">ODbL License</a>. ' + 'Tiles © <a href="//carto.com/" title="Carto">Carto</a>',
	}).addTo(map);

	// Layer CartoDB
/*
	var layer = L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles <a href="//carto.com/attributions">CARTO</a> — Data <a href="http://www.overpass-api.de/">Overpass API</a>',
	}).addTo(map);
*/


/*
	var layer-basemap = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/base-light/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
	});
*/

	// Add dynamic URL hashes
	var hash = L.hash(map);

	// Add locate control - A leaflet control to geolocate the user.
	// https://github.com/domoritz/leaflet-locatecontrol
	var lc = L.control.locate({
		position: 'topleft',
		strings: {
			title: 'Show me where I am, yo!'
		}
	}).addTo(map);

	// Add geo search
	new L.Control.GeoSearch({
	    provider: new L.GeoSearch.Provider.OpenStreetMap(),
	    position: 'topleft',
	    showMarker: true,
	    retainZoomLevel: false,
		country: '',
		searchLabel: 'Ort suchen',
		notFoundMessage: 'Oops, diese Adresse konnte nicht gefunden werden.',
		messageHideDelay: 3000,
		zoomLevel: 16
	}).addTo(map);

	// Create popup content
	function PopupContent(title) {
		this.content = '' + title + '<br>';

		this.appendName = function(name) {
			var html = '<h4>' + name + '</h4>';
			this.content += html;
		};

		this.appendText = function(text) {
			var html = '<p>' + text + '</p>';
			this.content += html;
		};

		this.appendWebsite = function(website) {
			var html = '<b>Biografie:</b> <a href="' + website + '" title="Biografie unter: ' + website + '" target="_blank">Website</a><br>';
			this.content += html;
		};

		this.appendAddress = function(address) {
			var html = '<b>Adresse:</b> ' + address + '<br>';
			this.content += html;
		};

		this.appendNote = function(note) {
			var html = '<b>Notiz:</b> ' + note + '<br>';
			this.content += html;
		};

		this.appendNetwork = function(network) {
			var html = '<b>Network:</b> ' + network + '<br>';
			this.content += html;
		};

		this.appendFooter = function(type, id) {
			var html = '<small><a href="http://www.openstreetmap.org/' + type + '/' + id + '" title="Eintrag in OpenStreetMap ansehen / editieren" target="_blank">Details anzeigen</a></small>';
			this.content += html;
		};

	};

	// Get overpass data
	var stolperstein = new L.OverPassLayer({
		minzoom   : 16,
		//endpoint  : 'http://overpass.osm.rambler.ru/cgi/',
		endpoint  : 'https://overpass-api.de/api/',
		query     : 'node(BBOX)["memorial:type"="stolperstein"];out;',
		//query     : 'node(BBOX)[amenity=post_box];out;',
		//query     : 'node(BBOX)[amenity=post_box];node(BBOX)[amenity=cafe];node(BBOX)[amenity=restaurant];out;',
		//query     : '(node["traffic_signals:vibration"](BBOX);node["traffic_signals:floor_vibration"](BBOX);node["traffic_signals:arrow"](BBOX););(._;>;);out;',
		debug     : false,

		callback: function(data) {

			for(var i=0;i<data.elements.length;i++) {
				var e = data.elements[i];

				if (e.id in this.instance._ids) return;
				this.instance._ids[e.id] = true;

				var popupContent = new PopupContent( 'Info' );

				if (e.tags.name) {
					popupContent.appendName(e.tags.name);
				}

				if (e.tags['memorial:text']) {
					popupContent.appendText(e.tags['memorial:text']);
				}

				if (e.tags['memorial:addr']) {
					popupContent.appendAddress(e.tags['memorial:addr']);
				}

				if (e.tags['memorial:note']) {
					popupContent.appendNote(e.tags['memorial:note']);
				}

				if (e.tags['memorial:website']) {
					popupContent.appendWebsite(e.tags['memorial:website']);
				}

				if (e.tags.network) {
					popupContent.appendNetwork(e.tags.network);
				}

				// Configure OSM edit link
				popupContent.appendFooter(e.type, e.id);

				// Configure markers
				//var markerColor = e.tags['memorial:text'] ? 'green':'lightgreen';
				//var markerColor = e.tags.name ? 'green':'orange';

				var markerColor = e.tags['memorial:text'] ? 'blue':'lightblue';
				//var markerColor = 'blue';

/*
				if  e.tags.name = '') {
					var markerIcon = 'map-marker';
					var markerColor = 'red';
					} else {
					var markerIcon = 'map-marker';
					var markerColor = 'green';
				};
*/

				var marker = L.AwesomeMarkers.icon({
					icon: 'map-marker',
					prefix: 'fa',
					markerColor: markerColor,
					iconColor: 'white',
					spin: false
				});


				var pos = new L.LatLng( e.lat, e.lon );
				var marker = L.marker( pos, {icon: marker} ).bindPopup(popupContent.content);

				this.instance.addLayer(marker);

			}
		},

		// Zoom message
		minZoomIndicatorOptions: {
			position: 'topleft',
			minZoomMessage: 'Zoom-Level: CURRENTZOOM <br> Daten werden ab Level MINZOOMLEVEL geladen.'
		},

		// Progress bar
		beforeRequest: function() {
			console.log( 'Overpass query start' );
			NProgress.configure({
				showSpinner: true
			});
			NProgress.start();
		},
		afterRequest: function() {
			console.log( 'Overpass query done' );
			NProgress.done();
		},

	}).addTo(map);

});
